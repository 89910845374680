/* CircularProgress.css */
.progress-ring {
  transform: rotate(-90deg); /* Start the progress from the top */
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round; /* This gives the rounded cap effect */
}
